// src\components\MultiAudioHandler.jsx

import { useRef, useState } from "react";
import { fetchAndNormalizeAudio, normalizeAudio } from "./AudioNormalizer";

const MultiAudioHandler = ({ audioFile, onFinish }) => {
    const isStopped = useRef(false);
    const [isRunning, setIsRunning] = useState(false);

    const handleMultiClick = async () => {
        isStopped.current = false;
        setIsRunning(true);

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        const playAudio = async (buffer) => {
            return new Promise((resolve) => {
                const source = audioContext.createBufferSource();
                source.buffer = buffer;
                source.connect(audioContext.destination);
                source.start(0);

                source.onended = () => resolve();

                if (isStopped.current) {
                    source.stop();
                    resolve();
                }
            });
        };

        const recordAudio = async (duration) => {
            return new Promise(async (resolve) => {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const recorder = new MediaRecorder(stream);
                let chunks = [];

                recorder.ondataavailable = (e) => chunks.push(e.data);
                recorder.onstop = async () => {
                    const blob = new Blob(chunks, { type: "audio/webm" });
                    stream.getTracks().forEach((track) => track.stop());
                    resolve(blob);
                };

                recorder.start();
                setTimeout(() => {
                    if (!isStopped.current) recorder.stop();
                }, duration);
            });
        };

        const beepSound = async () => {
            return new Promise((resolve) => {
                const oscillator = audioContext.createOscillator();
                const gainNode = audioContext.createGain();

                oscillator.type = "sine";
                oscillator.frequency.setValueAtTime(1000, audioContext.currentTime);
                gainNode.gain.value = 0.2;

                oscillator.connect(gainNode).connect(audioContext.destination);
                oscillator.start();
                setTimeout(() => {
                    oscillator.stop();
                    resolve();
                }, 100);
            });
        };

        try {
            const normalizedAudioBuffer = await fetchAndNormalizeAudio(audioContext, audioFile.url);

            for (let i = 0; i < 5; i++) {
                if (isStopped.current) break;

                console.log(`Starting loop ${i + 1}`);

                console.log("Playing audio chunk...");
                await playAudio(normalizedAudioBuffer);

                if (isStopped.current) break;

                await sleep(500); // Pause

                console.log("Beep before recording...");
                await beepSound();

                if (isStopped.current) break;

                console.log("Recording user...");
                const chunkDuration = normalizedAudioBuffer.duration * 1000;
                const recordingTime = chunkDuration * 1.5;
                const recordedBlob = await recordAudio(recordingTime);

                if (isStopped.current) break;

                console.log("Playing user recording...");
                const userRecordingBuffer = await audioContext.decodeAudioData(
                    await recordedBlob.arrayBuffer()
                );
                const normalizedUserRecording = await normalizeAudio(audioContext, userRecordingBuffer, 0.1);
                await playAudio(normalizedUserRecording);

                console.log(`Finished loop ${i + 1}`);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            isStopped.current = false;
            setIsRunning(false);
            onFinish && onFinish();
        }
    };

    const handleStopClick = () => {
        isStopped.current = true;
        console.log("Multi process stopped immediately.");
        setIsRunning(false);
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    return (
        <button
            onClick={isRunning ? handleStopClick : handleMultiClick}
            className={`px-4 py-2 rounded text-black ${
                isRunning
                    ? "bg-red-500 hover:bg-red-700"
                    : "bg-purple-500 hover:bg-purple-700"
            }`}
        >
            {isRunning ? "Stop" : "PlayItSayIt"}
        </button>
    );
};

export default MultiAudioHandler;
