// Main.jsx

import React, { useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { signInWithGooglePopup, auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import googleIcon from "./../assets/google.png";

const Main = () => {
  const [user, setUser] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [videos, setVideos] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserVideos(user.email);
    }
  }, [user]);

  const handleGoogleSignIn = async () => {
    try {
      const res = await signInWithGooglePopup();
      setUser(res.user);
    } catch (error) {
      console.error("Google Sign-In Error:", error.message);
      setErrorMsg("Failed to sign in. Please try again.");
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
    setUser(null);
  };

  const handleVideoLinkChange = (e) => {
    setVideoLink(e.target.value);
  };

  const fetchUserVideos = async (userEmail) => {
    try {
      const response = await fetch(
        `https://api.alindor.online/user-videos?user_email=${userEmail}`
      );
      const data = await response.json();
      if (data.status === "success") {
        // Sort videos by date (earliest videos first)
        const sortedVideos = data.videos.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setVideos(sortedVideos);
      } else {
        setErrorMsg("Failed to fetch user videos.");
      }
    } catch (error) {
      console.error("Error fetching user videos:", error);
      setErrorMsg("Failed to fetch user videos.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!videoLink) {
      setErrorMsg("Please provide a YouTube link.");
      return;
    }

    setErrorMsg("");
    setSuccessMsg("Processing (~60 seconds)...");

    try {
      const formBody = new URLSearchParams();
      formBody.append("video_url", videoLink);
      formBody.append("user_email", user?.email || "guest@example.com");

      // Submit video processing request
      const response = await fetch(
        "https://api.alindor.online/audio_splitter/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
          body: formBody.toString(),
        }
      );

      const data = await response.json();
      if (!data.task_id) {
        throw new Error("Task ID not received from the server.");
      }

      const taskId = data.task_id;
      let taskStatus = "PENDING";
      let result;

      // Polling for task status
      while (taskStatus === "PENDING" || taskStatus === "STARTED") {
        const pollUrl = `https://api.alindor.online/tasks/${taskId}`;
        const pollResponse = await fetch(pollUrl, {
          method: "GET",
          headers: { Accept: "application/json" },
        });

        if (!pollResponse.ok) {
          throw new Error(
            `HTTP error while polling! status: ${pollResponse.status}`
          );
        }

        const pollData = await pollResponse.json();
        taskStatus = pollData.task_status;

        if (taskStatus === "SUCCESS") {
          result = pollData.task_result;
          break;
        } else if (taskStatus === "FAILURE") {
          throw new Error("Task failed on the server.");
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      if (result && result.firebase_folder_link) {
        setSuccessMsg("Video successfully processed!");

        // Fetch the updated list of videos
        const videoListResponse = await fetch(
          `https://api.alindor.online/user-videos?user_email=${user.email}`
        );

        if (!videoListResponse.ok) {
          throw new Error("Failed to fetch updated video list.");
        }

        const videoListData = await videoListResponse.json();

        if (
          videoListData.status === "success" &&
          videoListData.videos.length > 0
        ) {
          // Sort videos by date (latest videos first)
          const sortedVideos = videoListData.videos.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          const latestVideo = sortedVideos[0]; // Get the latest processed video

          console.log("Redirecting to the latest video:", latestVideo);

          // Navigate to the latest processed video
          navigate(`/play-it-say-it/${latestVideo.video_id}`, {
            state: { videoTitle: latestVideo.title },
          });
        } else {
          throw new Error("No videos found after processing.");
        }
      } else {
        throw new Error("Firebase folder link not found in the result.");
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      setErrorMsg(
        "An error occurred while processing your request. Please try again."
      );
      setSuccessMsg("");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <div className="absolute top-4 right-4">
        {!user ? (
          <button
            onClick={handleGoogleSignIn}
            className="flex items-center px-4 py-2 bg-white border rounded shadow hover:bg-gray-50"
          >
            <img src={googleIcon} alt="Google" className="w-6 h-6 mr-2" />
            <span>Sign in with Google</span>
          </button>
        ) : (
          <div className="flex items-center space-x-4">
            <p className="font-medium">{user.displayName}</p>
            <button
              onClick={handleLogout}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Logout
            </button>
          </div>
        )}
      </div>

      <div className="text-center">
        <h1 className="text-4xl font-bold mb-6">Welcome to PlayItSayIt!</h1>
        <h3 className="text-2xl font- mb-6">
          Enhance your accent by mimicking your favorite influencers—singers,
          actors, politicians, or entrepreneurs.
        </h3>
        <h3 className="text-2xl font- mb-6">
          Just find their solo YouTube speech, paste the link, and PlayItSayIt
          app will break it into small chunks for you to listen and repeat.
        
        </h3>

        {user ? (
          <>
            {videos.length > 0 ? (
              <div>
                <h2 className="text-2xl font-semibold mb-4">Your Videos</h2>
                <ul className="space-y-4">
                  {videos.map((video, index) => (
                    <li
                      key={index}
                      className="bg-white shadow p-4 rounded-md cursor-pointer"
                      onClick={() =>
                        navigate(`/play-it-say-it/${video.video_id}`, {
                          state: { videoTitle: video.title },
                        })
                      }
                    >
                      <p className="text-lg font-medium">{video.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <p className="text-lg text-gray-700 mb-8">
                You have no processed videos. Please add a new video to start.
              </p>
            )}

            <form
              onSubmit={handleSubmit}
              className="space-y-6 max-w-md mx-auto mt-6"
            >
              {errorMsg && <p className="text-red-500">{errorMsg}</p>}
              {successMsg && <p className="text-green-500">{successMsg}</p>}

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  YouTube Video Link
                </label>
                <input
                  type="url"
                  value={videoLink}
                  onChange={handleVideoLinkChange}
                  placeholder="Video URL (don't upload a video more than 5 min)"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>

              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Submit
              </button>
            </form>

            <p className="text-lg text-gray-700 mb-6">
              If you have some errors or questions, text to admin
              <img src="/email.png" alt="Email" className="mx-auto mb-6" />
            </p>
          </>
        ) : (
          <p className="text-lg text-gray-700 mt-4">
            Please sign in to use the application.
          </p>
        )}
      </div>
    </div>
  );
};

export default Main;
