export const fetchAndNormalizeAudio = async (audioContext, url, targetRMS = 0.1) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    return normalizeAudio(audioContext, audioBuffer, targetRMS);
  };
  
  export const normalizeAudio = async (audioContext, audioBuffer, targetRMS) => {
    const offlineContext = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      audioBuffer.length,
      audioBuffer.sampleRate
    );
  
    const calculateRMS = (data) => {
      let sumSquares = 0;
      for (let i = 0; i < data.length; i++) {
        sumSquares += data[i] * data[i];
      }
      return Math.sqrt(sumSquares / data.length);
    };
  
    const channelData = audioBuffer.getChannelData(0);
    const currentRMS = calculateRMS(channelData);
    const gainFactor = targetRMS / currentRMS;
  
    const gainNode = offlineContext.createGain();
    gainNode.gain.value = gainFactor;
  
    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(gainNode).connect(offlineContext.destination);
  
    source.start(0);
    return await offlineContext.startRendering();
  };
  