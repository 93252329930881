// PlayItSayIt.jsx

import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import handleDelete from "../components/handleDelete";
import MultiAudioHandler from "../components/MultiAudioHandler";

const PlayItSayIt = ({ user }) => {
  const { videoId } = useParams();
  const location = useLocation();
  const videoTitle = location.state?.videoTitle;

  const [audioFiles, setAudioFiles] = useState([]);
  const [expandedChunks, setExpandedChunks] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  const [chunksVolume, setChunksVolume] = useState(1); // Volume for audio chunks
  const [recordingsVolume, setRecordingsVolume] = useState(1); // Volume for recordings

  const [isRecording, setIsRecording] = useState(false);
  const [currentRecordingChunk, setCurrentRecordingChunk] = useState(null);

  const audioRefs = useRef({});
  const mediaRecorderRef = useRef(null); // Fix: Declare mediaRecorderRef

  useEffect(() => {
    if (!videoId) {
      setErrorMsg("No video selected. Please go back and select a video.");
      return;
    }

    if (!user?.email) {
      setErrorMsg("User email is not available. Please log in again.");
      return;
    }

    const fetchVideoDetails = async () => {
      try {
        const response = await fetch(
          `https://api.alindor.online/video/${videoId}?user_email=${encodeURIComponent(
            user.email
          )}`
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch video details! Status: ${response.status}`
          );
        }

        const data = await response.json();

        if (data.status === "success") {
          const { firebase_folder_link, chunks } = data.video_data;

          const sortedChunks = chunks
            .filter((chunk) => chunk?.file_name?.match(/chunk_(\d+)_/))
            .sort((a, b) => {
              const aIndex = parseInt(a.file_name.match(/chunk_(\d+)_/)[1], 10);
              const bIndex = parseInt(b.file_name.match(/chunk_(\d+)_/)[1], 10);
              return aIndex - bIndex;
            })
            .map((chunk, index) => ({
              url: `${firebase_folder_link}/${chunk.file_name}`,
              displayName: `Chunk ${index + 1}`,
              file_name: chunk.file_name,
              subtitle: chunk.text || "No subtitle available",
            }));

          setAudioFiles(sortedChunks);
        } else {
          throw new Error(data.message || "Failed to load video details.");
        }
      } catch (error) {
        setErrorMsg("Failed to load video details. Please try again.");
      }
    };

    fetchVideoDetails();
  }, [videoId, user]);

  useEffect(() => {
    Object.values(audioRefs.current).forEach((audioElement) => {
      if (audioElement) audioElement.volume = chunksVolume;
    });
  }, [chunksVolume]);

  const toggleExpand = (fileName) => {
    setExpandedChunks((prev) => ({
      ...prev,
      [fileName]: !prev[fileName],
    }));
  };

  const handleRecording = async (fileName) => {
    if (isRecording && currentRecordingChunk === fileName) {
      mediaRecorderRef.current.stop();
      return;
    }

    try {
      setIsRecording(true);
      setCurrentRecordingChunk(fileName);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks = [];
      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);

      mediaRecorder.onstop = async () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        const audioURL = URL.createObjectURL(blob);

        const audioElement = new Audio(audioURL);
        audioElement.play();

        setIsRecording(false);
        setCurrentRecordingChunk(null);

        stream.getTracks().forEach((track) => track.stop());
      };

      mediaRecorder.start();
      setTimeout(() => {
        if (mediaRecorder.state === "recording") {
          mediaRecorder.stop();
        }
      }, 5000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setIsRecording(false);
      setCurrentRecordingChunk(null);
    }
  };

  const onDeleteFile = (fileName) => {
    setAudioFiles((prevFiles) =>
      prevFiles.filter((file) => file.file_name !== fileName)
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <h1 className="text-4xl font-bold mb-6">Play It & Say It</h1>

      {errorMsg && <p className="text-red-500">{errorMsg}</p>}
      {videoTitle && <h2 className="text-2xl mb-4">{videoTitle}</h2>}

      <div className="mt-4 flex items-center justify-center">
        <span className="px-4 py-2 bg-purple-500 text-black text-lg font">
          PlayItSayIt
        </span>
        <span className="ml-4 text-gray-600 text-base">
          Listen to the audio chunk → Repeat → Listen to your recording → Do it 5
          times in a loop or more.
        </span>
      </div>

      {audioFiles.length === 0 ? (
        <p>No audio files available to play for this video.</p>
      ) : (
        <div className="space-y-4 w-full max-w-2xl mt-6">
          {audioFiles.map((file) => (
            <div
              key={file.file_name}
              className="bg-white shadow rounded-md p-4"
            >
              <div className="flex items-center mb-2">
                <p className="flex-1 truncate">{file.displayName}</p>
                <audio
                  controls
                  ref={(el) => (audioRefs.current[file.file_name] = el)}
                >
                  <source src={file.url} type="audio/mpeg" />
                </audio>
                <button
                  onClick={() =>
                    handleDelete({
                      fileName: file.file_name,
                      videoId,
                      userEmail: user.email,
                      onDelete: onDeleteFile,
                    })
                  }
                  className="ml-2 text-red-500"
                >
                  Delete
                </button>
              </div>

              <div className="flex space-x-4">
                <button
                  onClick={() => handleRecording(file.file_name)}
                  className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
                >
                  {isRecording && currentRecordingChunk === file.file_name
                    ? "Stop"
                    : "Repeat"}
                </button>

                <MultiAudioHandler audioFile={file} />

                <button
                  onClick={() => toggleExpand(file.file_name)}
                  className="px-4 py-2 bg-blue-200 text-black rounded hover:bg-blue-300"
                >
                  Text
                </button>
              </div>

              {expandedChunks[file.file_name] && (
                <p className="text-gray-500 mt-2">{file.subtitle}</p>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlayItSayIt;
